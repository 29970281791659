//tipografia
$fuente_principal: 'Montserrat', sans-serif;;


// colores

$negro:#000000;
$blanco: #ffffff;
$grisClaro:#696969;
$grisFondo:#f9f9f9;
$verdeClaro:#A2D9CE;
$naranja:#ff6716;



//tamaños de Media Queries

$telefono: 350px;
$tablet: 850px;
$desktop: 992px;
$desktopXL:2000px;