@use '../base/variables' as v;
@use '../base/mixins' as m;


.header{
    .header_img_blog{
        overflow: hidden;
        margin: auto;
        background-image: url(../img/headerblog.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 30rem;

        @include m.tablet {
            height: 65rem ;
            }
   
        .header_img-contenido-blog{
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            height: 30rem;
            background-color: rgba(0,0,0,.4);
            @include m.telefono{
            height: 30rem;
            }
            @include m.tablet {
            height: 65rem ;
            }
   
            h3,h1{
                text-align: center;
                width: 100%;
                animation-duration: 2s;
                animation-name: tituloblg;
                padding-top: 0;
                padding-left: 0;
                line-height: 3rem;
                @include m.telefono{
                    font-size: 3rem;
                }
                @include m.tablet{
                    font-size: 6rem;
                    line-height: 6rem;
                

                    @keyframes tituloblg {
                        from {
                           padding-top: 35rem;
                        }
                        to {
                           padding-top:0;
                        }                  
                        0%{
                           opacity:0.5;
                        }
                        100% {
                           opacity:1;
                       }
                   }
                }
            }

            .header_blog{
                width: 100%;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
                p{
                    width: 80%;
                    text-align: center;
                    font-size: 1.5rem;
                    padding: 0;
                    margin-left: auto;
                    margin-right: auto;

                    @include m.tablet{
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}

.contenido-principal{
    @include m.tablet{
        display: grid;
        grid-template-columns: 3fr 1fr;
        justify-content: center;
        gap: 3rem;
    }
          
   
    h3{
        font-size: 2.5rem;
    }

    .blog{
        .entrada{
            margin-top: 5rem;
            &_contenido{
                text-align: justify;
                h4{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.3rem;
                }
            }
        }
    }
    
    .entradas_relevantes{
        margin-top: 5rem;
    
        .entrada2{
            h2{
                font-size: 1.6rem;
                padding-top: 2rem;
                margin-bottom: 0;
            }
            p{
                font-size: 1.3rem;
                text-align: justify;
            }
        }
    }
}

.contenido-secundario{
    .blog{
        .entrada{
            margin-top: 5rem;
            &_contenido{
                text-align: justify;
                h4{
                    font-size: 2rem;
                }
                p{
                    font-size: 1.5rem;
                }
            }
        }
    }

    .todas_entradas{
        margin-top: 5rem;
        h3{
            font-size: 2.5rem;
        }

        .contenido_secundario_grid{
            @include m.tablet{
                @include m.grid (3, 2rem)
            }
        }       

        .entrada2{
            h2{
                font-size: 1.6rem;
                padding-top: 2rem;
                margin-bottom: 2rem;
            }
            p{
                font-size: 1.3rem;
                text-align: justify;
            }

            .imagen_todas_entradas{
                height: 227px;
                box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
            }
        }
    }
}

.campo_blog{
    border-radius: 20rem;
    width: auto;
    background-color: v.$naranja;
    border: solid v.$naranja;
    display: inline-block;
    text-align: center;
    margin-bottom: 2rem;
    padding: .7rem;
    .boton{
        color:v.$blanco;
        font-size: 1.5rem;
    }
} 

.campo_blog_mejor{
    width: auto;
    display: flex;
    text-align: center;
    margin-bottom: 2rem;
    padding: .7rem;
    align-items: center;

    .next-arrow{
    display: none;
    height: 10px;
    left: -12px;
    position: relative;
    top: auto;
    width: auto;
    }

    .line{
    position: relative;
    margin-top: auto;
    margin-right: 1rem;
    background-color: #ff6716;
    height: 1px;
    width: 20px;
    top: -7px;
    }
    .boton{
        display: flex;
        color:v.$naranja;
        font-size: 1.3rem;
        padding-left: 0.5rem;
        align-items: center;
        font-weight: 600;
        @include m.tablet{
            &:hover{
                font-weight: 700;
                .next-arrow{
                    display: block;
                    animation-name: aparecer;
                    animation-duration: 0.5s;
                    @keyframes aparecer{
                        0%{
                            opacity: 0;
                        }
                        50%{
                            opacity: 50%;
                        }
                        100%{
                            opacity: 100%;
                        }
                }
    
                }
                .line{
                    width: 25px;
                    animation-name: aparecer;
                    animation-duration: 0.5s;
                    @keyframes aparecer{
                    0%{
                        opacity: 0;
                    }
                    50%{
                        opacity: 50%;
                    }
                    100%{
                        opacity: 100%;
                    }
                }
                    
                }
            }
        }
    }
} 

