@use '../base/variables' as v;
@use '../base/mixins' as m;

.header{
    .barra{
        @include m.tablet {
        &.fijo{
            z-index: 1000;
            left: 0;
            top: 0;
            position: fixed;
            width: 100%;
            box-shadow: .1rem .2rem .3rem #333;
            background-color: v.$blanco;
            } 
        }
    
        .barra_contenido{
        margin-right: 1rem;
        margin-left: 1rem;
        text-align: center;
        padding: 0.5rem;  
        display: flex;
        justify-content: space-between;
        align-items: center;  

        &.mostrar{
            flex-direction: column;
        }
       
            @include m.tablet {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;
                padding: 1rem;  
                margin-right: 5rem;
                margin-left: 5rem;
                &.mostrar{
                    flex-direction: row;
                }
                }
            

            .logo{
                display: flex;
                justify-content: left;
                width: 15rem;
                &.mostrar{
                    justify-content: center;
                }    
                @include m.telefono{
                    width: 20rem;
                }
                @include m.tablet {
                    justify-content: left;
                    &.mostrar{
                        justify-content: left;
                    }
                }             
            }  

            .idioma_flex{
                display: flex;
                align-items: center;
                .idioma{
                    width: 3rem;
                    &.mostrar{
                        display: none;
                    }  
                    @include m.tablet {
                        &.mostrar{
                            display: block;
                        }
                    } 
                }
            }

        }       

        
        .seleccion{
            display: flex;
            gap:2rem;
            align-items: center;
            flex-direction: row;
            &.mostrar{
                flex-direction: column;
            }    
            @include m.tablet {
                &.mostrar{
                    flex-direction: row;
                }
            }   

            .menu-tlf{
                display:block;
    
                @include m.tablet{
                    display: none;
                }
            }

            .navegacion{
                display: none; 
                &.mostrar{
                    display: block;
                    animation-duration: .5s;
                    animation-timing-function: linear;
                    animation-name: menu_tlf;
                        @keyframes menu_tlf {
                            0%{
                                opacity:0;
                            }
                            50%{
                                opacity: 0.5;
                            }
                            100% {
                                opacity:1;
                                }
                            }
                    
                } 

                @include m.tablet {
                    display: flex;
                    &.mostrar{
                        display: flex;
                    } 
                }
                gap: 2rem;
                text-align: center;   

                a{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: v.$negro;
                    font-size: 1.7rem;
                    background: linear-gradient(v.$negro 0 0) 0 100% /var(--d, 0) 2px 
                    no-repeat;
                    transition:.3s; 
                    margin-left: auto;
                    margin-right: auto;
                    width: auto;
                    height: 48px;
                    @include m.tablet {
                        padding-bottom: 0; 
                        display: block;
                        width: auto;
                        height: auto;

                    }
                    &:hover{
                        text-decoration: underline;  
                    }                         
                    &.under{
                        --d: 100%;
                    }
                } 
            }    
        }
    }  

    .header_img{
        overflow: hidden;
        margin: auto;
        background-image: url(../img/nosotros.jpg);
        height: 45rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        &.detalleServicios{
            background-color: rgba(0,0,0,.4);
            height: 30rem; 
            @include m.tablet {
                height: 45rem;
            } 
                @include m.telefono{
                    h1{
                      text-align: left;
                      margin-left: 6rem;  
                      font-size: 3rem;
                      line-height: 40px;
                    }
                }

                @include m.tablet{
                    h1{
                      text-align: left;
                      margin-left: 3rem;
                      font-size: 3rem;
                      line-height: 40px;    
                    }
                }

            
        }
        @include m.tablet {
            height: 70rem ;
        }

        &-contenido{
         
            .box{
                width: 80%;
                align-self: start;
                margin-left: 3rem;
                BORDER-RADIUS: 5%;
                background-color: rgba(0,0,0,.5);

                @include m.telefono{
                width: 75%;
                }

                @include m.desktop{
                    width: 35%;
                    margin-left: 5rem;
                }

                @include m.desktopXL{
                    margin: 0 0 0 90rem;
                    width: 25%;

                }
         }
            background-color: rgba(0,0,0,.1);
            height: 45rem;
            color: v.$blanco;
            font-family: v.$fuente_principal;
            align-content: stretch;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: space-evenly;
            align-items: center;

            &.detalleServicios{
                height: 30rem; 
                background-color: rgba(0,0,0,.4);
                @include m.tablet {
                    h1{
                        font-size: 4rem;
                    }
                    height: 45rem;
                } 
            }
            
            .widthmax{
                display: flex;
                margin: auto;
                padding-bottom: 3rem;
                @include m.desktopXL{
                    max-width:1280px;
                    margin: 0 auto;
                }
            }

            @include m.tablet {
                align-content: center;
                height: 70rem;
                align-items: center;
                justify-content: center;
            } 
                
            h3,h1{
                text-shadow: 0px 0px 10px rgb(0 0 0 / 84%);
                margin-bottom: 0;
                margin-left: 3rem;
                line-height: 20px;
                font-size: 1.5rem;
                font-weight: 500;
                text-align: left;
                padding-top: 3rem;
                padding-right: 3rem;

                @include m.telefono{
                    line-height: 28px;
                    font-size: 2.5rem;
                }

                @include m.tablet {
                    width: auto;
                    text-align: center;
                    font-size: 3rem;
                    line-height: 37px;
                    margin-bottom: 2rem;
                    margin-left: 3rem;
                    margin-right: 3rem;
                    text-align: left;
                    padding-top: 3rem;
                    animation-duration: 2s;
                    animation-name: titulo;

                        @keyframes titulo {
                            from {
                                padding-top: 35rem;
                                }
                            to {
                                padding-top:3rem;
                                }                  
                            0%{
                                opacity:0;
                                }
                            50%{
                                opacity: 0.5;
                            }

                            100% {
                                opacity:1;
                            }
                        }
                    }

                    @include m.desktopXL{
                        text-align: left;
                        max-width:1280px;
                        padding-left: 0;
                    }
                }

                .header_img-complemento{
                    float: center;
                    width: auto;

                    @include m.desktopXL{
                        max-width:1280px;
                        margin-left: 3rem;
                    }

                    @include m.telefono{
                    width: 65%;

                   
                    }
                    @include m.tablet{
                        width: 65%;
                        margin-top: 2rem;
                        animation: toUp 2s;
                        @keyframes toUp {
                            from{
                                margin-top: 15rem;
                            }
                            to{
                                margin-top: 2rem;
                            }
                            0%{
                                opacity:0;
                                }
                            50%{
                                opacity: 0.5;
                            }
                            
                            100% {
                                opacity:1;
                            }
                        }       
                    }

                                 
        
                    p{
                        width: 80%;
                        color: v.$blanco;
                        font-size: 1.3rem;
                        font-weight: 400;
                        text-align: left;
                        margin-left: 3rem;
                        text-shadow: 0px 0px 10px rgb(0 0 0 / 84%);
                        span{
                            font-weight: 500;
                        }  
                        @include m.telefono
                        {
                            font-size: 1.5rem;
                            width: 130%;
                        }
                        @include m.tablet {
                            font-size: 1.7rem;
                            margin: auto;
                            margin-left: 3rem;
                            width: 140%;
                        }
                        @include m.desktopXL{
                            text-align: left;
                            margin: 0;
                        }
                        
                    }                
                }

                .campo_header{
                    border-radius: 1rem;
                    width: auto;
                    padding: 0.5rem;
                    background-color: v.$naranja;
                    border: solid v.$naranja;
                    display: block;
                    margin-left: 3rem;
                    margin-right: auto;
                    text-align: center;
                    padding-top: 15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                    padding-left: 15px;
                    border-width: 0.2rem;

                    @include m.telefono{
                        width: auto;
                    }
                    
                    @include m.tablet {
                        margin-top: 3rem;
                        
                        width: auto;
                       
                    }

                    .boton{
                        color:v.$blanco;
                        font-size: 1rem;
                        @include m.telefono{
                            font-size: 1rem;
                        }
                        @include m.tablet{
                            font-size: 1.3rem;
                            animation: toUp 2s;
                        @keyframes toUp {
                            from{
                                margin-top: 15rem;
                            }
                            to{
                                margin-top: 2rem;
                            }
                            0%{
                                opacity:0;
                                }
                            50%{
                                opacity: 0.5;
                            }
                            
                            100% {
                                opacity:1;
                            }
                        }
                            
                        }
                        &:hover{
                            font-weight: 700;
                        }
                    }

                        &_mas{
                            background-color: rgba(0,0,0,.5);

                            a{
                                color: v.$naranja;
                            }
                            &:hover{
                                background-color: v.$naranja;
                            }
                        }
                }
            }       
        }
}
  