@use '../base/variables' as v;
@use '../base/mixins' as m;

.nosotros{  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .displa{
        display: flex;
        flex-direction: column;
        margin-left: 5rem;
        margin-right: 5rem;
        width: 76%;
        @include m.tablet{
            gap: 5rem;
            width: 90%;
        }

        .div{
            box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
            padding-left: 2rem;
            padding-right: 2rem;
            margin-top: 5rem;
            margin-bottom: 1rem;
            p{
                color: #696969;
            }
            .sanrubio{
                color: v.$naranja;
                font-weight: 500;
            }

            
        }
    }

    @include m.desktopXL{
        max-width:1280px;
        margin: 0 auto;
    }

    @include m.tablet {
        height: 80rem;
        min-height: 75rem;
        &.servicios{
            height: 90rem;
            min-height: 90rem;

            h2{
                text-align: left;
            }

            .displa{
                display: flex;
                flex-direction: row;
                gap: 5rem;
                margin-left: 5rem;
                margin-right: 5rem;
    
                .div{
                    box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
                    padding-left: 2rem;
                    padding-right: 2rem;
                    margin-bottom: 5rem;
                    p{
                        color: #696969;
                        font-size: 1.3rem;
                    }
                }
            }

            &.servicios_2{
                height: 100%;
                margin-top: 10rem;
            }
        }

        &_grid{
            display: grid;
            grid-template-columns: 1.5fr 2fr;
        }

        .animation{
            animation-name: slidein;
            animation-duration: 1.5s;
                @keyframes slidein {
                from {
                    padding-top: 40rem;
                    }
                to {
                    padding-top: 0;
                    }                  
                0%{
                    opacity:0;
                    }
                100% {
                    opacity:1;
                    }
                }
        }
    }

       
    h2{
                color: v.$negro;
        font-size: 3.5rem;
        line-height: 4rem;
        font-weight: 400;
        text-align: left;
        padding-left: 0;
        margin-bottom: 5rem;
        padding-top: 2rem;
        padding-left: 5rem;

        @include m.tablet {
            text-align: left;
            padding-left: 4.5rem;
            font-size: 4.5rem;
            line-height: 5rem;
            padding-top: 0;
            }                       
    }

    &_contenido{
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 3rem;

        .sanrubio{
            color:v.$naranja;
            font-weight: 500;
        }

        p{
            margin-left: 5rem;
            margin-right: 5rem;
            color: v.$grisClaro;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 2.5rem;
            
            text-align: justify;
            span{
                font-weight: 500;
            }

            @include m.tablet {
                padding-left: 4.5rem;
                margin-left: 0;
                margin-right: 0;
                }   
        }

        
    }
 
    &_img{
    display: none;
    @include m.tablet {
        display: flex;
    }
    &.servicios{
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        align-items: center;
        @include m.tablet{
            align-items: flex-end;
        }

        .zinde{
            border-radius: 2%;
            background-color: white;
            box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
            width: 75%;
            margin-top: 2rem;
            margin-bottom: 5rem;
                @include m.tablet{
                    width: 50%;
                    margin-right: 5rem;
                    z-index: 1;
                    margin-top: -200px;
                }

            }
            p{
                display: flex;
                column-gap: 1rem;
                padding-left: 2rem;
                padding-bottom: 1rem;
                padding-right: 2rem;
                font-size: 1.5rem;
                text-align: left;
                align-items: center;
                color: #696969;
                width: 100%;
                margin-bottom: 1rem;
                margin-top: 1rem;
                span{
                    color: v.$naranja;
                    font-weight: 500;
                }
            }
    }
    
        &_contenido{
            overflow: hidden;
            margin: auto;
            max-width: 80%;
            width: 75%;
            align-items: center;
            box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
            border-radius: 3%;

            @include m.tablet {
                    &.animation_ns{
                        animation-name: boxs;
                        animation-duration: 2s;
                        transition: linear;
                        @keyframes boxs {
              
                        0%{
                            opacity: 0;
                            transform: scale(0);;
                            }
        
                         100% {
                            opacity: 1;
                            transform: scale(1);
                            }
                        }
                    }
                } 

            
        } 
      }
}
