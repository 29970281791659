@use '../base/variables' as v;
@use '../base/mixins' as m;

.nota{
    background-color: #f9f9f9;
    text-align: center;
    overflow: hidden;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include m.telefono{
        height: 25rem;
    
    }
    @include m.tablet{
        height: 50rem;
    }

    h3{
        padding-left: 2rem;
        padding-right: 2rem;
        font-size: 1.9rem;
        line-height: 2.5rem;
        margin-bottom: 0;
        font-weight: 350;
        overflow: hidden;
        font-style: italic;
        transform: scale(0.94);
        animation: scale 10s forwards cubic-bezier(0.5, 1, 0.89, 1);

        @include m.desktopXL{
          max-width:1280px;
          margin: 0 auto;
      }
        @include m.telefono{
            font-size: 2rem;
            line-height: 2.7rem;
        }
        @include m.tablet{ 
            font-size: 3rem; 
            line-height: 4rem;          
        
        &.animation_nota{
          animation-name: boxs;
          animation-duration: 2.5s;
          transition: linear;
          @keyframes boxs {
          0%{
            opacity:0;
              transform: scale(0);;
            }
            50%{
                opacity: 0.5;
            }
        
          100% {
            opacity: 1;
              transform: scale(1);
            }
           }
        }
      }
    }
}
    .linea_boton{
      width: auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: flex-start;
      p{
        font-size: 1.5rem;
        @include m.tablet{
            font-size: 2rem;
            
        }
      }    
        .next-arrow{
        display: none;
        height: 10px;
        left: -12px;
        position: relative;
        top: auto;
        width: auto;
        }
    
        .line{
        position: relative;
        margin-top: auto;
        margin-right: 1rem;
        background-color: #ff6716;
        height: 1px;
        width: 20px;
        top: -9px;
        }
        .boton{
            
            &.boton_nosotros{
                margin-left: 5rem;
                padding-left: 0;
                padding-top: 2rem;
                font-weight: 500;
            }

            display: flex;
            color:v.$naranja;
            font-size: 1.6rem;
            align-items: center;
            font-weight: 300;
            @include m.tablet{
            font-weight: 400;
            &:hover{
                font-weight: 700;
                .next-arrow{
                    display: block;
                    animation-name: aparecer;
                    animation-duration: 0.5s;
                    @keyframes aparecer{
                        0%{
                            opacity: 0;
                        }
                        50%{
                            opacity: 50%;
                        }
                        100%{
                            opacity: 100%;
                        }
                    }
        
                    }
                    .line{
                        width: 25px;
                        animation-name: aparecer;
                        animation-duration: 0.5s;
                        @keyframes aparecer{
                        0%{
                            opacity: 0;
                        }
                        50%{
                            opacity: 50%;
                        }
                        100%{
                            opacity: 100%;
                        }
                    }
                        
                    }
            }
          }
        }
    } 

