@use '../base/variables' as v;
@use '../base/mixins' as m;


.header{
    .header_img-510{
        overflow: hidden;
        margin: auto;
        background-image: url(../img/510k.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        height: 25rem;
        @include m.tablet{
                height: 40rem ;
            }

        .header_img-contenido{
            height: 25rem ;
            align-content: stretch;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            background-color: rgba(0,0,0,.4);
            @include m.tablet{
                height: 40rem ;
            }
            h3{
                text-align: center;
                width: 100%;
                padding-left: 5rem;
                padding-right: 5rem;
                animation-duration: 2s;
                animation-name: titulo510;
                @include m.tablet{
                    @keyframes titulo510 {
                        from {
                            padding-top: 25rem;
                            }
                        to {
                            padding-top:0;
                            }                  
                        0%{
                            opacity:0.5;
                            }
                        100% {
                            opacity:1;
                        }
                    }
                }  
            }
        }
    }
}
.contenido_blog{
   h3{
       text-align: center;
   }
    h2{
        text-align: center;
        font-size: 2.5rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    p{
        font-size: 1.5rem;
    text-align: justify;
    }

    li{
        font-size: 2rem;
    text-decoration: underline;
    }

    .contenido_blog_grid{
        margin-top: 5rem;
        @include m.tablet{
            @include m.grid(2,2rem);
        }

    .div_img{
        display: none;
        @include m.tablet{
            display: flex;
            align-items: center;
            justify-content: center;
        }       
        
        .img_blog{
            box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
            width: 80%;
            height: 80%;
            display: inline-block;
        }
    }
    .div_img_tlf{
        display: flex;
            align-items: center;
            justify-content: center;
        @include m.tablet{
            display: none;
        }    
    }
}
}