@use '../base/variables' as v;
@use '../base/mixins' as m;

.servicios{
    text-align: center;
    overflow: hidden;

    h2{
        padding-top: 5rem;
        color:v.$negro;
        font-size: 3.5rem;
        line-height: 4rem;
        font-weight: 400;
        text-align: left;

        @include m.telefono{
            padding-top: 5rem;  
        }

        @include m.tablet {
            font-size: 4rem;
            display:flex;
            align-items: center;
            justify-content: space-around;
            line-height: 5rem;
            }      
    }
    
    h3{
        font-size: 2rem;
        margin-bottom: 2rem;
        text-align: left;
        font-weight: 400;
        color: #000;
    }
    p{
        font-size: 1.5rem;
        text-align: left;
        color: v.$grisClaro;
        line-height: 2.4rem;
        @include m.telefono{
            width: 75%;
        }

        @include m.tablet{
            width: 90%;
        }
    }

    @include m.tablet{
        .animation_sv{
            animation-name: slideup;
            animation-duration: 1.5s;
            @keyframes slideup {
            from {
                padding-top: 30rem;
                }
            to {
                padding-top:0;
                }                  
            0%{
                opacity:0.5;
                }
            100% {
                opacity:1;
                }
            }
        }
    }
   

    .img_servicio{
        display: flex;

        &_contenido{
            display: none;
            @include m.tablet{
            display: block;
            }
            
            margin: auto;
            max-width: 80%;
            width: 65%;
            align-items: center;
            box-shadow: 0 0 2rem rgb(0 0 0 / 30%);
        }
    }

    &.fondo{
        background-color:v.$grisFondo ;
        box-shadow: 0 0 0.2rem rgb(0 0 0 / 30%);
    }

    &_grid{

        margin-left: 5rem;
        margin-right: 5rem;
        padding-top: 0;
        padding-bottom: 3rem;  

        @include m.telefono{
            padding-top: 2rem;
            padding-bottom: 2rem;  
        }
        
        @include m.tablet{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 5rem;
            padding-top: 5rem;
            padding-bottom: 5rem;  
        }
        @include m.desktopXL{
            max-width:1280px;
            margin: 0 auto;
        }
   
        .margin_servicios{
            margin: auto;

            .widthmax{
                display: flex;
                margin: auto;
                padding-bottom: 3rem;
                @include m.desktopXL{
                    max-width:1280px;
                    margin: 0 auto;
                }


                .campo_header{
                    border-radius: 1rem;
                    width: auto;
                    padding: 0.5rem;
                    background-color: v.$naranja;
                    border: solid v.$naranja;
                    display: block;
                    margin-right: auto;
                    text-align: center;
                    padding-top: 15px;
                    padding-right: 15px;
                    padding-bottom: 15px;
                    padding-left: 15px;
                    border-width: 0.2rem;

                    @include m.telefono{
                        width: auto;
                    }
                    
                    @include m.tablet {
                        width: auto;
                       
                    }

                    .boton{
                        color:v.$blanco;
                        font-size: 1rem;
                        @include m.telefono{
                            font-size: 1rem;
                        }
                        @include m.tablet{
                            font-size: 1.3rem;
                            animation: toUp 2s;
                        @keyframes toUp {
                            from{
                                margin-top: 15rem;
                            }
                            to{
                                margin-top: 2rem;
                            }
                            0%{
                                opacity:0;
                                }
                            50%{
                                opacity: 0.5;
                            }
                            
                            100% {
                                opacity:1;
                            }
                        }
                            
                        }
                        &:hover{
                            font-weight: 700;
                        }
                    }

                        &_mas{
                            background-color: rgba(0,0,0,.5);

                            a{
                                color: v.$naranja;
                            }
                            &:hover{
                                background-color: v.$naranja;
                            }
                        }
                }
            }

 
        }

        .servicio1, .servicio2, .servicio3{
            display: flex;
            margin-bottom: 1rem;

            p{
                span{
                    font-weight: 500;
                }
            }
            @include m.telefono{
                margin-bottom: 3rem;
                border-bottom: solid #ca9375;
                padding-left: 1rem;
                padding-right: 1rem;

                &:hover{
                    background-color: #ca9375;
                    border-radius: 2%;
                    box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
                    h3,p{
                        color:whitesmoke;
                    }
                    h3{
                        font-weight: 400;
                        font-size: 2rem;
                    }
                    border-bottom: none;
                    .numero{
                        color: whitesmoke;
                        font-weight: 300;
                        font-size: 3rem;
                    }
                }
            }
            @include m.tablet {
                padding-left: 2rem;
                padding-right: 2rem;
                text-align: left;
                min-height: 30rem;
                border-bottom: none;

                &:hover{
                    background-color: #ca9375;
                    border-radius: 2%;
                    box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
                    h3,p{
                        color:whitesmoke;
                    }
                    h3{
                        font-weight: 500;
                        font-size: 2.3rem;
                    }

                    .numero{
                        color: whitesmoke;
                        font-weight: 400;
                        font-size: 3.3rem;
                    }
                }

                }   
            .numero{
                color: #ff6716;
                font-size: 3rem;
                font-weight: 300;
            }

            .margin_servicios{
                margin: auto;
            }

        }

        .grid_4{
            @include m.tablet{
                display: grid;
                grid-template-columns: repeat(2,1fr);
            }
        }
    }

   .servicios_contenido{
        .img_servicio_contenido_tlf{
                display: inline-block;
                width: 45%;
                margin-bottom: 2rem;
                box-shadow: 0 0 0.8rem rgb(0 0 0 / 30%);
                @include m.tablet{
                    display: none;
                }
            }
            


        p{
            color: v.$grisClaro;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 400;
            padding-left: 4.5rem;
            padding-right: 4.5rem;
            text-align: left;

            span{
                font-weight: 700;
            }
        }

        ul{
            color: v.$grisClaro;
            text-align: left;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.5rem;
            padding-left: 8rem;
            padding-right: 4.5rem;
            li{
                span{
                    font-weight: 700;
                }
            }
        }

        .campo_servicio{
            border-radius: 20rem;
            width: auto;
            background-color: v.$naranja;
            border: solid v.$naranja;
            display: inline-block;
            margin-top: 2rem;
            padding-top: 15px;
            padding-right: 20px;
            padding-bottom: 15px;
            padding-left: 20px;
            .boton{
                color:v.$blanco;
                font-size: 2rem;
                &:hover{
                    font-weight: 700;
                }
            }
        }
    }
    
}
