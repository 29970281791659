@use '../base/variables' as v;
@use '../base/mixins' as m;

.trabajemos{
    text-align: center;
    overflow: hidden;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include m.telefono{
        height: 20rem;
    }
    @include m.tablet{
        height: 40rem;
    }
    .campo_blog{
        border-radius: 1rem;
        width: auto;
        padding: 0.5rem;
        background-color: v.$naranja;
        border: solid v.$naranja;
        display: block;
        margin: 0 auto;
        text-align: center;
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        padding-left: 15px;
        border-width: 0.2rem;

        @include m.telefono{
            width: auto;
        }
        
        @include m.tablet {
            width: auto;
           
        }

        .boton{
            color:v.$blanco;
            font-size: 1rem;
            @include m.telefono{
                font-size: 1rem;
            }
            @include m.tablet{
                font-size: 1.3rem;
                animation: toUp 2s;
            @keyframes toUp {
                from{
                    margin-top: 15rem;
                }
                to{
                    margin-top: 2rem;
                }
                0%{
                    opacity:0;
                    }
                50%{
                    opacity: 0.5;
                }
                
                100% {
                    opacity:1;
                }
            }
                
            }
            &:hover{
                font-weight: 700;
            }
        }

            &_mas{
                background-color: rgba(0,0,0,.5);

                a{
                    color: v.$naranja;
                }
                &:hover{
                    background-color: v.$naranja;
                }
            }
    }


    h3{
        font-size: 1.3rem;
        margin-bottom: 0;
        font-weight: 350;
        overflow: hidden;
        margin-left: 3rem;
        margin-right: 3rem;
        padding-top: 1rem;
        

       
        @include m.telefono{
            font-size: 1.8rem;
            margin-left: 4rem;
            margin-right: 4rem;
            padding-bottom: 3rem;
            line-height: 2rem;
        }
        @include m.tablet{
            font-size: 3rem;
            padding-bottom: 5rem;
            line-height: 4rem;
        
            &.animation_tr{
                animation-name: tr;
                animation-duration: 2s;
                @keyframes tr {
                    from {
                    padding-top: 20rem;
                        }
                    to {
                        padding-top: 0;
                        }                  
                    0%{
                        opacity:0.5;
                        }
                    100% {
                        opacity:1;
                        }
                }
            }
        }

        @include m.desktopXL{
            max-width:1280px;
            margin: 0 auto;
        }
    }

}
