@use '../base/variables' as v;
@use '../base/mixins' as m;

.header_img-SGC{
    overflow: hidden;
    margin: auto;
    background-image: url(../img/calidad.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 25rem;
        @include m.tablet{
                height: 40rem ;
            }
    
 .header_img-contenido{
        height: 25rem ;
        align-content: stretch;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.4);
        @include m.tablet{
            height: 40rem ;
        }
    h3{
        text-align: center;
        width: 100%;
        padding-left: 5rem;
        padding-right: 5rem;
        animation-duration: 2s;
        animation-name: titulosgc;
        @include m.tablet{
            @keyframes titulosgc {
                from {
                    padding-top: 35rem;
                    }
                to {
                    padding-top:0rem;
                    }                  
                0%{
                    opacity:0.5;
                    }
                100% {
                    opacity:1;
                }
            }
        }    
    }
 }
}

.grid_sgc{
    h2{
        margin-top: 5rem;
    }
    @include m.tablet{
        @include m.grid(2,2rem);
    }

    p{
        span{
            font-weight: 700;
        }
    }
}