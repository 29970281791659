@use '../base/variables' as v;
@use '../base/mixins' as m;

.fondo{
    background-color:v.$grisFondo ;
    box-shadow: 0 0 0.2rem rgb(0 0 0 / 30%);
}
.contactanos{
    background-color:v.$grisFondo;
    width: 100%;
    padding-bottom: 5rem;
    text-align: center;
    @include m.desktopXL{
        max-width:1280px;
        margin: 0 auto;
    }

    .form_grid{
        
        @include m.tablet{
            display: grid;
            grid-template-columns: 1fr 1.5fr;
            justify-content: center;
            align-items: center;
        }

        .datos_contacto{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            
            @include m.tablet{
                margin-left: 10rem;
                align-items: flex-start;
            }

            h2  {
                font-size: 1.7rem;
                margin-bottom: 2rem;
                text-align: center;
                color: v.$negro;
                font-weight: 300;
                @include m.tablet{
                    text-align: left;
                }
                .sanrubio{
                    color:v.$naranja;
                    font-weight: 400;
                }
            }

            p{
                text-align: center;
                padding-left: 3rem;
                padding-right: 3rem;
                font-size: 1.5rem;
                margin-top: 0;
                width: auto;
                line-height: 1.2;
                .sanrubio{
                    color:v.$naranja;
                    font-weight: 500;
                }

                &.telefono{
                    margin-left: 3rem;
                }

                &.contacto_pais{
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1rem;
                    img{
                        width: 2rem;
                    }
                }

                span{
                    font-weight: 700;
                }
                    @include m.telefono{
                        width: 80%;
                    }
                    @include m.tablet{
                        text-align: left; 
                        padding-left: 0;
                        padding-right: 0;
                        width: auto;
                    }
            }
            a{
                color: black;
                font-size: 1.5rem;
                margin-bottom: 1.5rem;
            }

            .campo_contacto{
                border-radius: 1rem;
                width: auto;
                padding: 0.5rem;
                background-color: v.$naranja;
                border: solid v.$naranja;
                display: block;
                margin-right: 0;
                text-align: center;
                padding-top: 15px;
                padding-right: 15px;
                padding-bottom: 15px;
                padding-left: 15px;
                border-width: 0.2rem;

                @include m.telefono{
                    width: auto;
                    
                }
                
                @include m.tablet {
                    width: auto;
                    margin-right: auto;
                }

                .boton{
                    color:v.$blanco;
                    font-size: 1rem;
                    @include m.telefono{
                        font-size: 1rem;
                    }
                    @include m.tablet{
                        font-size: 1.3rem;
                        animation: toUp 2s;
                    @keyframes toUp {
                        from{
                            margin-top: 15rem;
                        }
                        to{
                            margin-top: 2rem;
                        }
                        0%{
                            opacity:0;
                            }
                        50%{
                            opacity: 0.5;
                        }
                        
                        100% {
                            opacity:1;
                        }
                    }
                        
                    }
                    &:hover{
                        font-weight: 700;
                    }
                }
            }
        }

    }

    &_contenido{
        width: auto;
        margin: 5px auto 20px auto;
        overflow: hidden;
        @include m.telefono{
            width: 80%;
        }
        @include m.tablet{
            width: 50%;
        }
        
        h2{
            overflow: hidden;
            color: v.$negro;
            font-size: 3rem;
            line-height: 4rem;
            font-weight: 400;
            padding-top: 5rem;
            margin-bottom: 0;
            @include m.telefono{
                font-size: 3rem;
            }
            @include m.tablet{
                font-size: 4.2rem;
                padding-bottom: .5rem;
            }
          }
         p{
            border-bottom: 1px solid rgba(0,0,0,.15);
            color: v.$grisClaro;
            text-align: center;
            margin-bottom: 5rem;
            padding-bottom: 3rem;
            font-size: 1.5rem;

            @include m.tablet{
                font-size: 2rem;
            }
         }
    }

.formulario{

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;

        @include m.tablet {
            width: 70%;
            margin-top: 0;
        }
        .campo{
            display: flex;
            margin-bottom: 2rem;
            width: 60%;
    
            &__field{
                background-color: v.$grisFondo;
                border-width: thin;
                flex: 1;
                border-radius: 3px;
                border-color: rgb(188, 167, 197);
                margin-bottom: 1rem;
                font-size: 1.6rem;
                line-height: 24px;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                width: 100%;
                height: 90%;
    
                &--textarea{
                    height: 120px;
                }
            }  
        } 

        .boton{
            background-color: v.$grisClaro;
            font-family: v.$fuente_principal;
            color: v.$blanco;
            padding: 1rem 3rem;
            font-size: 1.8rem;
            font-weight: 700;
            width: 100%;
            border: none;
            border-radius: 0.4rem;
    
            &:hover{
                cursor: pointer;
            }
        }
    } 
}

