@use '../base/variables' as v;
@use '../base/mixins' as m;

.footer{
    background-color: v.$negro;
    text-align: left;
    padding: 1rem 0 1rem 3rem;
        span{
            font-weight: 500;
            }
        p{
            color: v.$blanco;
            font-size: 1rem;           
        } 
    }

